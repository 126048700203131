<template>
  <div class="col-md-12 col-lg-10 center">
    <CCard class="card-margin" v-if="session">
      <CCardBody>
        <CRow class="align-items-center">
          <CCol> Session ID: {{ session.conversation_id }} </CCol>
          <CCol> Session Name: {{ session.conversation_name }} </CCol>
        </CRow>
      </CCardBody>
    </CCard>
    <template v-for="message in messages">
      <CCard :class="{ 'assistant-card': message.role === 'assistant', 'user-card': message.role === 'user' }"
        class="card-margin">
        <CCardBody>
          <CRow class="align-items-center">
            <CCol md="auto" class="c-row-top">
              <div v-if="message.role === 'assistant'" class="c-avatar">
                <img src="/img/assistant-logo.png" class="c-avatar-img" />
              </div>
              <div v-if="message.role === 'user'" class="c-avatar">
                <img src="/img/avatars/boy.png" class="c-avatar-img" />
              </div>
            </CCol>
            <CCol class="d-flex flex-column">
              <div>
                <span v-html="markdownToHtml(message.content, message.showCaret)" style="white-space: normal"></span>
                <!--<span class="caret" v-if="message.showCaret === true">|</span>-->
              </div>
              <div v-if="message.role === 'assistant' && message.speech_url">
                <CButton :color="message.playing ? 'danger' : 'primary'" @click="togglePlayback(message)">
                  {{ message.playing ? 'Stop Audio' : 'Play Audio' }}
                </CButton>
              </div>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </template>
    <CCard class="card-margin">
      <CCardHeader> {{ guidanceText }}. <a href="#" @click="() => { topupModal = true }">Example questions</a>
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol>
            <CAlert :color="alertColor" closeButton :show.sync="alertShow">
              {{ alertMessage }}
            </CAlert>
          </CCol>
        </CRow>
        <CRow class="align-items-center card-margin-one">
          <CCol md="auto">
            <CButton :color="recording ? 'danger' : 'primary'" @click="toggleRecording"
              class="button-margin no-margin-right">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" role="img"
                class="c-icon c-icon-custom-size icon-margin" height="32">
                <path fill="var(--ci-primary-color, currentColor)"
                  d="M256,328a96.108,96.108,0,0,0,96-96V112a96,96,0,0,0-192,0V232A96.108,96.108,0,0,0,256,328ZM192,112a64,64,0,0,1,128,0V232a64,64,0,0,1-128,0Z"
                  class="ci-primary"></path>
                <path fill="var(--ci-primary-color, currentColor)"
                  d="M400,176v56c0,79.4-64.6,144-144,144S112,311.4,112,232V176H80v56c0,91.653,70.424,167.154,160,175.265V496h32V407.265C361.576,399.154,432,323.653,432,232V176Z"
                  class="ci-primary"></path>
              </svg>
              &nbsp;&nbsp;{{ recording ? 'Stop Recording' : 'Start Recording' }}
            </CButton>
          </CCol>
        </CRow>
        <CRow class="align-items-center">
          <CCol>
            <CTextarea label="" placeholder="Send a message" rows="3" v-model="newMessage" @keyup.enter="sendMessage"
              class="no-bottom">
            </CTextarea>
          </CCol>
          <CCol md="auto">
            <CButton color="primary" @click="sendMessage" class="small-button"> Send </CButton>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
    <CModal title="Example questions" color="info" :show.sync="topupModal" @update:show="closeModal">
      Following are example questions
      <ul>
        <li>Berapa jumlah total pembangkit listrik yang ada?</li>
        <li>Berapa jumlah total pembangkit listrik yang ada?</li>
        <li>Bagaimana distribusi status pembangkit listrik?</li>
        <li>Tamplikan distribusi status pembangkit listrik dalam pie chart</li>
        <li>Apa saja jenis pembangkit listrik yang ada dalam database?</li>
        <li>Pembangkit listrik mana yang memiliki kapasitas daya terpasang tertinggi?</li>
        <li>Berapakah total kapasitas daya bersih dari semua pembangkit listrik?</li>
        <li>Berapa banyak pembangkit listrik yang mulai beroperasi pada tahun tertentu?</li>
        <li>Provinsi mana yang memiliki pembangkit listrik terbanyak?</li>
        <li>Bagaimana distribusi kepemilikan pembangkit listrik?</li>
        <li>Pembangkit listrik mana yang memiliki aset terbanyak?</li>
        <li>Apa merek mesin yang paling umum digunakan dalam aset pembangkit listrik?</li>
        <li>Bagaimana perbandingan antara daya terpasang dan kapasitas daya bersih untuk setiap pembangkit?</li>
        <li>Berapa rata-rata tahun operasi pembangkit listrik di setiap provinsi?</li>
        <li>Aset pembangkit listrik mana yang memiliki kemampuan pasokan tertinggi?</li>
        <li>Berapa banyak pembangkit listrik yang dalam kondisi baik dibandingkan dengan yang membutuhkan perawatan?</li>
        <li>Bagaimana distribusi geografis pembangkit listrik (berdasarkan latitude dan longitude)?</li>
        <li>Bagaimana perbandingan antara kepemilikan mesin dan kepemilikan listrik untuk setiap aset?</li>
        <li>Berapa total produksi harian (dalam kWh) untuk tanggal {{ formattedDate }}?</li>
        <li>Pembangkit listrik mana yang memiliki daya maksimum harian (DMH) tertinggi pada tanggal {{ formattedDate }}?</li>
        <li>Apa saja jenis bahan bakar yang digunakan dalam produksi listrik?</li>
        <li>Bagaimana variasi produksi harian untuk berbagai jenis bahan bakar?</li>
        <li>Aset mana yang saat ini beroperasi dan mana yang tidak beroperasi?</li>
        <li>Berapa rata-rata usia aset pembangkit listrik?</li>
        <li>Bagaimana perbandingan antara daya terpasang aset dengan kapasitas keseluruhan pembangkit?</li>
        <li>Organisasi mana yang memiliki pembangkit listrik terbanyak?</li>
        <li>Bagaimana tren produksi listrik dari waktu ke waktu untuk pembangkit atau aset tertentu?</li>
        <li>Bagaimana efisiensi (produksi vs kapasitas) bervariasi di antara berbagai jenis pembangkit?</li>
      </ul>
    </CModal>
  </div>
</template>

<script>
import axios from "axios";
import { marked } from "marked";
import Pusher from 'pusher-js'

const MediaRecorder = window.MediaRecorder || window.webkitMediaRecorder || window.mozMediaRecorder || window.msMediaRecorder;

export default {
  name: "SessionDetail",
  data() {
    return {
      alertColor: "success",
      alertShow: false,
      alertMessage: "",
      recording: false,
      recordedChunks: [],
      mediaRecorder: null,
      mediaStream: null,
      sessionID: "",
      session: null,
      messages: [],
      newMessage: "",
      guidanceText: "",
      pusherClient: null,
      topupModal: false,
      today: new Date(),
      audio: null,
      playing: false,
    };
  },
  mounted() {
    this.guidanceText = "Start recording or type a message to ask the AI assistant"

    // Get session if session ID is provided
    if (this.$route.params.id != undefined) {
      this.sessionID = this.$route.params.id;

      axios
        .get("/sessions/" + this.$route.params.id, {
          headers: {
            'API-KEY': config.VUE_APP_ENV_ASSISTANT_API_KEY
          }
        })
        .then((resp) => {
          this.session = resp.data.session;
          this.sessionID = resp.data.session.conversation_id;
          this.messages = resp.data.messages.map(message => {
            if (message.speech_url) {
              message.playing = false;
            }
            return message;
          });

          console.log('Session ID:', this.sessionID);
          console.log('Messages:', this.messages);

          this.initPusher();
        })
        .catch((err) => {
          this.showAlert("warning", "Failed to get session : " + err);
        });
    } else {
      // Create new session
      axios
        .post("/sessions", {}, {
          headers: {
            'API-KEY': config.VUE_APP_ENV_ASSISTANT_API_KEY
          }
        })
        .then((resp) => {
          this.session = resp.data.session;
          this.sessionID = resp.data.session.conversation_id;
          console.log('Session ID:', this.sessionID);

          this.initPusher();
        })
        .catch((err) => {
          this.showAlert("warning", "Failed to create session : " + err);
        });
    }
  },
  beforeDestroy() {
    if (this.pusherClient) {
      this.pusherClient.unsubscribe(this.pusherChannelName);
      this.pusherClient.unbind_all();
      this.pusherClient.disconnect();
      this.pusherClient = null;
    }
  },
  computed: {
    formattedDate() {
      const year = this.today.getFullYear();
      const monthNames = ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"];
      const month = monthNames[this.today.getMonth()];
      const day = this.today.getDate();
      return `${day} ${month} ${year}`;
    },
    lastWeekFormattedDate() {
      const lastWeek = new Date(this.today);
      lastWeek.setDate(this.today.getDate() - 7);

      const year = lastWeek.getFullYear();
      const monthNames = ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"];
      const month = monthNames[lastWeek.getMonth()];
      const day = lastWeek.getDate();

      return `${day} ${month} ${year}`;
    }
  },
  methods: {
    generateRandomString(length) {
      let result = '';
      const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
      const charactersLength = characters.length;

      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }

      return result;
    },
    markdownToHtml(content, showCaret) {
      if (showCaret) {
        content += ' <span class="caret">|</span>';
      }
      let rawHtml = marked(content, { breaks: true });

      // Modify iframe URLs
      // Modify iframe URLs and set width to 800
      rawHtml = rawHtml.replace(/<iframe(.+?)src="([^"]+)"([^>]*)>/g, (match, beforeSrc, url, afterSrc) => {
        const separator = url.includes('?') ? '&' : '?';
        const modifiedUrl = `${url}${separator}bg=555555`;
        
        // Remove existing width attribute if present
        const attrs = (beforeSrc + afterSrc).replace(/\bwidth=["'][^"']*["']/g, '');
        
        return `<iframe${attrs} src="${modifiedUrl}" width="1000" height="600">`;
      });

      return rawHtml;
      //return DOMPurify.sanitize(rawHtml);
    },
    showAlert(color, messsage) {
      this.alertShow = true;
      this.alertColor = color;
      this.alertMessage = messsage;
    },
    toggleRecording() {
      if (this.recording) {
        this.stopRecording();
      } else {
        this.startRecording();
      }
    },
    startRecording() {
      navigator.mediaDevices.getUserMedia({ audio: true })
        .then(stream => {
          this.mediaStream = stream;
          this.mediaRecorder = new MediaRecorder(stream);

          this.recordedChunks = [];
          this.mediaRecorder.ondataavailable = e => {
            if (e.data.size > 0) {
              this.recordedChunks.push(e.data);
            }
          };

          this.mediaRecorder.onstop = () => {
            const blob = new Blob(this.recordedChunks, { type: 'audio/webm;codecs=opus' });
            this.sendAudio(blob);
          };

          this.mediaRecorder.start();
          this.recording = true;
        })
        .catch(err => {
          console.log('Recording failed: ', err);
        });
    },
    stopRecording() {
      if (this.mediaRecorder) {
        // This will change the state to 'inactive'.
        this.mediaRecorder.stop();

        // Stop all tracks to release the media resources
        if (this.mediaStream) {
          this.mediaStream.getTracks().forEach(track => track.stop());
        }

        this.recording = false;
      }
    },
    sendAudio(blob) {
      const formData = new FormData();
      formData.append('speech', blob);

      axios.post("/transcript", formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'API-KEY': config.VUE_APP_ENV_ASSISTANT_API_KEY
        }
      }).then(resp => {
        console.log('Response: ', resp);
        this.newMessage = resp.data.transcript;
        this.sendMessage();
      }).catch(err => {
        this.showAlert("warning", "Failed to send message: " + err);
      });
    },
    sendMessage() {
      if (this.newMessage.trim() !== '') {
        const messageData = {
          text: this.newMessage,
        };

        this.messages.push({ role: "user", content: this.newMessage, showCaret: false });
        this.messages.push({ role: "assistant", content: "", showCaret: true });

        axios.post("/completionPusher?session_id=" + this.sessionID, messageData, {
          headers: {
            'API-KEY': config.VUE_APP_ENV_ASSISTANT_API_KEY
          }
        })
          .then((resp) => {
            this.newMessage = "";

            // Fetch data again to repopulate the messages
            axios
              .get("/sessions/" + this.sessionID, {
                headers: {
                  'API-KEY': config.VUE_APP_ENV_ASSISTANT_API_KEY
                }
              })
              .then((resp) => {
                this.session = resp.data.session;
                this.sessionID = resp.data.session.conversation_id;
                this.messages = resp.data.messages.map(message => {
                  if (message.speech_url) {
                    message.playing = false;
                  }
                  return message;
                });

                this.initPusher();
              })
              .catch((err) => {
                this.showAlert("warning", "Failed to get session : " + err);
              });
          })
          .catch((err) => {
            if (JSON.stringify(err.response.data).includes("Banned")) {
              this.newMessage = "";

              let lastAssistantMessageIndex = -1;
              for (let i = this.messages.length - 1; i >= 0; i--) {
                if (this.messages[i].role === 'assistant') {
                  lastAssistantMessageIndex = i;
                  break;
                }
              }

              if (lastAssistantMessageIndex != -1) {
                this.messages[lastAssistantMessageIndex].content = "Banned words detected. Please try again. This will not be recorded."
                this.messages[lastUserMessageIndex].showCaret = false
              }

            } else {
              console.log(err.response.data)
              this.showAlert("warning", "Failed to send message: " + err.response.data.message);
            }
          });
      }
    },
    togglePlayback(message) {
      if (message.playing) {
        this.audio.pause();
        this.audio = null;
        message.playing = false;
      } else {
        var audioFullUrl = config.VUE_APP_ENV_ASSISTANT_URL + "/file?name=" + message.speech_url;
        this.audio = new Audio(audioFullUrl);
        this.audio.play().then(() => {
          message.playing = true;
          this.audio.onended = () => {
            message.playing = false;
            this.audio = null;
          };
        }).catch(err => {
          this.showAlert("warning", "Failed to play audio: " + err);
          message.playing = false;
          this.audio = null;
        });
      }
    },
    playAudio(audioUrl) {
      this.audio = new Audio(audioUrl);
      this.audio.play().then(() => {
        console.log('1. play set to true');
        this.playing = true;
        this.audio.onended = () => {
          console.log('2. play set to false');
          this.playing = false;
          this.audio = null;
        };
      }).catch(err => {
        this.showAlert("warning", "Failed to play audio: " + err);
        this.playing = false;
        this.audio = null;
      });
    },
    initPusher() {
      if (this.pusherClient == null) {
        this.pusherClient = new Pusher(config.VUE_APP_PUSHER_KEY, { cluster: config.VUE_APP_PUSHER_CLUSTER });
        this.pusherChannelName = `channel-${this.sessionID}`;
        this.pusherClient.subscribe(this.pusherChannelName);
        this.pusherClient.bind('text-stream-assistant', data => {
          console.log('assistant text:', data)
          if (this.messages.length > 0) {
            let lastAssistantMessageIndex = -1;
            for (let i = this.messages.length - 1; i >= 0; i--) {
              if (this.messages[i].role === 'assistant') {
                lastAssistantMessageIndex = i;
                break;
              }
            }

            if (lastAssistantMessageIndex != -1) {
              var prevMessage = this.messages[lastAssistantMessageIndex].content
              if (prevMessage === undefined) {
                prevMessage = ""
              }
              if (data === undefined) {
                data = ""
              }
              this.messages[lastAssistantMessageIndex].content = prevMessage + data
              this.messages[lastAssistantMessageIndex].showCaret = true
            }
          }
        })
        this.pusherClient.bind('audio-file', data => {
          console.log('assistant speech:', data)

          const messageIndex = this.messages.length - 1;
          if (messageIndex >= 0 && this.messages[messageIndex].role === 'assistant') {
            this.messages[messageIndex].speech_url = data;
            this.messages[messageIndex].playing = false;
          } else {
            this.messages.push({ role: "assistant", content: "", speech_url: data, showCaret: false, playing: false });
          }
        })
      }
    },
    closeModal(status, evt, accept) {
    },
  }
};
</script>


<style>
.center {
  margin: 0 auto;
}

.card-margin {
  margin-bottom: 0.2rem;
}

.card-margin-one {
  margin-bottom: 1.0rem;
}

.button-margin {
  margin-right: 0.5rem;
}

.bottom {
  position: relative;
  float: center;
  clear: both;
  margin-top: auto;
}

.c-row-top {
  align-self: flex-start;
}

.assistant-card {
  background-color: #f0f8ff;
  /* color for assistant card, change as needed */
}

.user-card {
  background-color: #fafad2;
  /* color for user card, change as needed */
}

.caret {
  font-weight: bold;
  animation: blink 1s step-end infinite;
  display: inline;
}

@keyframes blink {

  from,
  to {
    color: transparent;
  }

  50% {
    color: black;
  }
}

.small-button {
  height: 30px;
  padding: 0 12px;
  margin-bottom: 1rem;
  font-size: 0.875rem;
}

.no-bottom {
  margin-bottom: 0px;
}

.no-margin-right {
  margin-right: 0px;
}
</style>
